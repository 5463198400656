<template>
  <form @submit.prevent="setPassword">
    <card header-classes footer-classes="has-content-justified-end">
      <template slot="header">
        <p class="card-header-title">Change password</p>
      </template>

      <template slot="body">
        <b-field label="Current password">
          <b-input
            v-model="oldPassword"
            type="password"
            autocomplete="password"
            placeholder="Please type your current password"
          />
        </b-field>

        <create-password
          ref="createPassword"
          :user-id="userId"
          confirm
          expand
          label="New Password"
          @validated="password = $event"
        />
      </template>

      <template slot="footer">
        <button
          :class="{ 'is-loading': processing }"
          :disabled="formIsDisabled"
          type="submit"
          class="button is-success has-margin-100"
        >
          Change password
        </button>
      </template>
    </card>
  </form>
</template>

<script>
export default {
  name: "ChangePasswordModal",
  components: {
    "create-password": () => import("@shared/account/_createPassword")
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      password: "",
      processing: false,
      oldPassword: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    formIsValid() {
      return !!this.oldPassword.length && !!this.password.length;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    }
  },
  created() {
    this.$store
      .dispatch("user/observeUser", { userId: this.userId })
      .catch(this.userNotFound);
  },
  beforeDestroy() {
    this.$store.dispatch("user/unobserveUser", { userId: this.userId });
  },
  methods: {
    userNotFound() {
      console.error("User not found");
    },
    setPassword() {
      this.processing = true;

      this.$store
        .dispatch("reseller/changePassword", {
          userId: this.userId,
          password: this.password,
          oldPassword: this.oldPassword
        })
        .then(result => {
          this.processing = false;
          this.password = "";
          this.oldPassword = "";
          this.$refs.createPassword && this.$refs.createPassword.reset();
          this.$emit("changed");

          if (result && result.message) {
            this.$toast.open({
              message: `${result.message}`,
              position: "is-bottom",
              type: "is-success"
            });
          }
        })
        .catch(error => {
          this.processing = false;
          if (error && error.message) {
            this.$toast.open({
              message: `${error.message}`,
              position: "is-bottom",
              type: "is-danger",
              queue: false,
              duration: 3000
            });
          }
        });
    }
  }
};
</script>
